<template>
    <div class="bg-white block w-full md:flex h-full relative mx-auto">
        <div class="relative h-full md:w-[1200px] mx-auto">
            <div class="w-full h-full overflow-x-hidden">
                <div class="relative h-full w-full">
                    <div class="py-4 px-4 md:px-4 text-gray-700 pb-[50px] gap-x-2 sm:block border-gray-200">
                        <div class="flex justify-end">
                            <button class="flex flex-wrap mt-4 ml-0 text-black font-semibold py-2 px-4 border rounded-lg focus:outline-none focus:shadow-outline gap-x-2" type="button">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20px" height="20px" class="mx-auto" id="close"><path d="M13.41,12l6.3-6.29a1,1,0,1,0-1.42-1.42L12,10.59,5.71,4.29A1,1,0,0,0,4.29,5.71L10.59,12l-6.3,6.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l6.29,6.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"/></svg>
                                Clear inputs
                            </button>
                        </div>
                        <div class="text-md font-bold pb-2 border-[#DEE8F9]"><span class="text-lg ml-1">What do you want to write thesis about?</span></div>
                        <div class="flex justify-end font-semibold">
                            <p>Write about Topic (0/60)</p>
                        </div>

                        <textarea rows="9" v-model="topic" class="w-full border-gray-200 rounded-md mt-1" placeholder="Enter Text to Paraphrase" :class="{ 'border-red-500': isTextareaEmpty }" @input="isTextareaEmpty = topic.trim() === ''" required></textarea>
                        <div v-if="isTextareaEmpty" class="text-xs text-red-500"> {{ errorMessage }} </div>

                        <div class="text-base text-right text-gray-700 flex justify-end">
                            <label for="default-range" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white mx-1 my-auto">Creativity:</label>
                            <input id="default-range" type="range" value="50" class="mx-1 h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700 my-auto">
                            <span>(7/10)</span>
                        </div>

                        <!--  <p class="text-xs text-right text-gray-700" :class="topic.length > 250 ? 'text-red-400':''">{{topic.length}}/250</p>-->
                            <p class="font-semibold">Statement Length:</p>
                            <div class="mt-5 sm:w-fit sm:px-4 py-2 rounded-md flex md:flex-no-wrap xs:flex-wrap md:gap-4 xs:gap-1 justify-center bg-[#E6DEFE] text-[#49378C] dark:text-black cursor-pointer md:text-base md:font-semibold xs:text-sm">
                                <div class="px-4 rounded-md bg-white">Short</div>
                                <div class="px-4 hover:border-b-2 rounded-b-md">Medium</div>
                                <div class="px-4 hover:border-b-2 rounded-b-md">Long</div>
                                <div class="px-4 hover:border-b-2 rounded-b-md">Extensive</div>
                            </div>

<!--
                        <label class="flex text-sm font-semibold mt-3">Add reference </label>
                        <textarea rows="3" v-model="reference" class="w-full border-gray-200 rounded-md mt-1" :class="{ 'border-red-500': isTextareaNull }" @input="isTextareaNull = reference.trim() === ''" required></textarea>
                        <div v-if="isTextareaNull" class="text-xs text-red-500"> {{ errorMessage }} </div>
-->

                        <div class="w-full py-2">
                            <div id="cf-container"></div>
                        </div>
                        <div class="mx-auto flex justify-center text-center">
                            <button @click="doThesisStatementGenerator()" class="flex flex-wrap mt-4 ml-0 bg-gradient-custom text-white font-semibold py-2 px-5 rounded focus:outline-none focus:shadow-outline gap-x-2" type="button">
                                <svg v-if="loading" class="animate-spin  h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                                Generate
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
<!--        <div class="flex-1 h-auto bg-white border-l">
            <QuillEditor theme="snow" v-model:content="editortext" contentType="text" toolbar="minimal" class="h-auto" />
            <ActionButtonsComponent />
        </div>
        <ErrorPopupComponent :errorMessage="serverErrorMessage" :errorHeader="yourErrorHeaderVariable"  ref="errorPopup" />-->


    </div>
</template>

<script>
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';

import { ref } from "vue";
import axios from "axios";
import ActionButtonsComponent from '@/Components/ActionButtonsComponent.vue';
import ErrorPopupComponent from '@/Components/ErrorPopupComponent.vue';
export default {
    components: {
        QuillEditor,
        ActionButtonsComponent,
        ErrorPopupComponent
    },
    props: {
        translations: Object,
    },
    data() {
        return {
            topic: '',
            reference: '',
            editortext: null,
            loading: false,
            sitekey:'0x4AAAAAAAfG9c7j47GarhAN',
            token: null,
            rawtext:'',
            isTextareaEmpty: false,
            isTextareaNull: false,
            errorMessage: "This field is required.",
            serverErrorMessage: "",
            yourErrorHeaderVariable: "",


        };
    },
    mounted(){
        this.renderCf();
    },
    beforeMount() {
        this.cfInit();
    },
    methods:{
        cfInit(){
            const e = document.createElement("script"), t = "https://challenges.cloudflare.com/turnstile/v0/api.js";
            e.src = `${t}?onload=onloadTurnstileCallback&render=explicit`, e.async = !0, e.defer = !0, document.head.appendChild(e);
        },
        renderCf(){
            let _this = this;
            window.onloadTurnstileCallback = function () {
                turnstile.render('#cf-container', {
                    sitekey: _this.sitekey,
                    callback: function(token) {
                        _this.verify(token);
                    },
                });
            };
        },
        verify(token){
            this.token = token;
        },
        doThesisStatementGenerator() {
            this.loading = true;
            axios
                .post("/tool/thesisStatementGenerator", {
                    capcha_token: this.token,
                    topic: this.topic,
                    reference: this.reference,
                    lang: 'english',
                    mode: 'creative',
                })
                .then(response=>{
                    this.loading = false;
                    if (response.data.content.length){
                        this.editortext = response.data.content;
                    }
                    turnstile.reset('#cf-container')
                })
                .catch(e => {
                    this.loading =false;
                    turnstile.reset('#cf-container');

                    const lettersOnly = /^[a-zA-Z\s]+$/;

                    /*if (e.response && e.response.status === 422) {
                        this.serverErrorMessage = e.response.data.message;
                        this.yourErrorHeaderVariable = "Error 422";
                    }
                    else */if (this.topic.trim() === '') {
                        this.errorMessage = "Input Text Required."; // Show validation message below textarea
                        this.isTextareaEmpty = true;
                        this.serverErrorMessage = "Input Text Required.";
                        this.yourErrorHeaderVariable = "Validation Error";
                        this.$refs.errorPopup.showError = true;
                    }
                    else if (!lettersOnly.test(this.topic)) {
                        this.errorMessage = "Only letters are allowed."; // Show validation message below textarea
                        this.isTextareaEmpty = true;
                        this.serverErrorMessage = "Only letters are allowed.";
                        this.yourErrorHeaderVariable = "Validation Error";
                        this.$refs.errorPopup.showError = true;
                    }
                    else if (this.topic.trim().length < 6) {
                        this.errorMessage = "Content is too short, Please add atleast 6 character"; // Show validation message below textarea
                        this.isTextareaEmpty = true;
                        this.serverErrorMessage = "Content is too short, Please add atleast 6 character";
                        this.yourErrorHeaderVariable = "Validation Error";
                        this.$refs.errorPopup.showError = true;
                    }
                    else if (this.reference.trim() === '') {
                        this.errorMessage = "Input Text Required."; // Show validation message below textarea
                        this.isTextareaNull = true;
                    }
                    else if (!lettersOnly.test(this.reference)) {
                        this.errorMessage = "Only letters are allowed."; // Show validation message below textarea
                        this.isTextareaNull = true;
                        this.serverErrorMessage = "Only letters are allowed.";
                        this.yourErrorHeaderVariable = "Validation Error";
                        this.$refs.errorPopup.showError = true;
                    }
                    else if (this.reference.trim().length < 6) {
                        this.errorMessage = "Content is too short, Please add atleast 6 character"; // Show validation message below textarea
                        this.isTextareaNull = true;
                        this.serverErrorMessage = "Content is too short, Please add atleast 6 character";
                        this.yourErrorHeaderVariable = "Validation Error";
                        this.$refs.errorPopup.showError = true;
                    }
                    else {
                        this.serverErrorMessage = "Something went wrong. Please try again and reload.";
                        this.yourErrorHeaderVariable = "Fetal Error";
                    }

                    // Show the error popup for other errors
                    this.$refs.errorPopup.showError = true;
                })
        }
    }
};

</script>
<style>
.ql-editor {
    height: 100% !important;
}
.ql-container {
    height: auto !important;
}
.ql-container.ql-snow {
    border: none!important;
}
.ql-toolbar.ql-snow{
    border: none!important;
    border-bottom: 1px solid rgb(229 231 235);
}
</style>
